@import "../helpers/variables.scss";

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

}

body {
    padding: 0 10px;

    @media screen and (min-width: $tablet) {
        padding: 0 40px;
    }

    &.menu-open {
        overflow: hidden;
    }
}

#smooth-wrapper {
    @media screen and (min-width: $tablet) {
        padding: 0 10px;
    }

    @media screen and (min-width: $desktop) {
        padding: 0 40px;
    }
}

section {
    padding: 0 10px;
}

header {
    .header {
        transform: translateY(0);
        transition: transform .4s;
    }

    &.menu-hidden {
        .header {
            transform: translateY(-170px);
            transition: transform .4s;
        }
    }
}

.header {

    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99;
    height: 65px;
    top: 20px;
    padding: 0 15px;
    margin: 0;
    max-width: 100vw;



    @media screen and (min-width: $tablet) {
        height: 65px;
        top: 30px;
        padding: 0 50px;
        margin: 0 auto;
        max-width: 1660px;
    }

    @media screen and (min-width: $desktop-lg) {
        height: 100px;
        top: 70px;
    }

    .header-left {

        .logo_img {
            height: 100%;
            border-radius: 10px;
        }
    }

    .header-right {
        display: flex;
        align-items: center;
        height: 100%;

        .linkedin {
            background-color: $dark-blue;

            svg {
                width: 20px;

                @media screen and (min-width: $desktop-lg) {
                    width: auto;
                }
            }
        }

        .linkedin {
            background-color: #007EBB;
            margin-left: 15px;
            border: 2px solid #007EBB;
            transition: .3s;

            svg {
                height: 16px;
                width: 16px;
            }

            @media screen and (min-width: $desktop-lg) {
                svg {
                    height: 24px;
                    width: 24px;
                }
            }

            &:hover {
                background-color: white;
                border: 2px solid #007EBB;
                transition: .3s;

                svg {
                    fill: #007EBB;
                    transition: .3s;
                }
            }
        }

        .mail {
            background-color: #6DB9DE;
            border: 2px solid #6DB9DE;
            transition: .3s;
            margin: 0 15px;

            svg {
                height: 20px;
                width: 20px;
            }

            @media screen and (min-width: $desktop-lg) {
                svg {
                    height: 29px;
                    width: 29px;
                }
            }

            &:hover {
                background-color: white;
                border: 2px solid #6DB9DE;
                transition: .3s;

                svg {
                    fill: #6DB9DE;
                    transition: .3s;
                }
            }
        }

        .hamburger-menu {
            background-color: #f5f5f5;
            position: relative;

            .hamburger-wrapper {
                &.menu-open {
                    .line.upper-line {
                        transform: rotate(45deg);
                        bottom: -2px;
                        transition: .3s;
                    }

                    .line.bottom-line {
                        transform: rotate(-45deg);
                        top: -1px;
                        transition: .3s;
                    }
                }

                .line {
                    height: 3px;
                    width: 20px;
                    background: $popup-blue;
                    position: relative;
                    bottom: 4px;
                    border-radius: 5px;
                    transition: .3s;

                    @media screen and (min-width: $tablet) {
                        width: 30px;
                    }

                    &.upper-line {
                        bottom: 3px;

                        @media screen and (min-width: $tablet) {
                            bottom: 4px;
                        }
                    }

                    &.bottom-line {
                        top: 3px;

                        @media screen and (min-width: $tablet) {
                            top: 4px;
                        }
                    }
                }

            }

            .header-popup {
                position: absolute;

                background: $popup-blue;
                border-radius: 15px;
                width: 480px;
                right: 0;
                z-index: 999;
                height: 0;
                width: 0;
                transition: .3s;
                font-size: 32px;
                line-height: 60px;
                top: 70px;

                @media screen and (min-width: $tablet) {
                    font-size: 48px;
                    line-height: 80px;
                    top: 100px;
                }

                &.menu-open {
                    width: calc(100vw - 30px);
                    height: 304px;
                    padding: 30px;
                    transition: .3s;
                    max-width: 380px;

                    @media screen and (min-width: $tablet) {
                        height: 384px;
                        width: 480px;
                        max-width: 480px;

                    }

                    li {
                        opacity: 1;
                        transition: .3s .2s
                    }

                }


                ul {
                    width: 100%;
                    text-align: right;

                    li {
                        position: relative;
                        z-index: 9;
                        cursor: pointer !important;
                        opacity: 0;
                        transition: .3s;

                        &:hover {
                            a.popup-anchor {
                                color: $white;
                                transition: .3s;
                            }
                        }

                        a.popup-anchor {
                            font-family: $font-sans;
                            font-weight: 300;
                            transition: .3s;
                            color: $white;

                            @media screen and (min-width: $tablet) {
                                color: rgba($white, .4);


                            }
                        }

                    }
                }
            }
        }
    }
}

.header-right {
    >:after {
        content: "";
        position: fixed;
        z-index: -1;


        animation: blur-out .3s forwards;
        height: 0;
        width: 0;
    }

    &.menu-open {

        >:after {

            animation: blur-in .3s forwards;
            width: 100vw;
            height: 100vh;
            left: 0;

            top: 0;
        }
    }
}

@keyframes blur-in {
    from {
        backdrop-filter: blur(0px);
    }

    to {
        backdrop-filter: blur(2px);
    }
}

@keyframes blur-out {
    from {
        backdrop-filter: blur(2px);
    }

    to {
        backdrop-filter: blur(0px);
    }
}