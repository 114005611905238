.section-hero {
    display: flex;
    height: 100vh;
    position: relative;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;


    @media screen and (min-width: $tablet) {
        flex-direction: row;
        margin-top: 80px;
        justify-content: start;
        align-items: center;
        height: auto;
    }

    @media screen and (min-width: $tablet) and (max-width: $desktop) {
        margin-top: 0;
        justify-content: space-between;
        height: 100vh;

    }



    @media screen and (min-width: $desktop) {
        margin-top: 0;
        height: 100vh;
        justify-content: start;

    }


    .hero-img-wrapper {
        display: flex;
        justify-content: center;
        max-width: 100vw;
        width: auto;

        @media screen and (min-width: $tablet) {
            width: 30%;
        }

        @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
            width: 40%;
        }

        .hero-img {
            max-width: 250px;
            margin-bottom: 20px;

            @media screen and (min-width: $tablet) {
                width: 80%;
                max-width: 464px;
                margin-bottom: 0;
                margin-top: 50px;
            }
        }
    }

    .arrow-down {
        position: absolute;
        bottom: 100px;
        left: 0;
        animation: jumpInfinite 1.5s infinite;

        svg {
            fill: $blue;
        }

        @media screen and (min-width: $desktop) {
            bottom: 80px;
        }
    }

    @keyframes jumpInfinite {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(-20px);

        }

        100% {
            transform: translateY(0);

        }
    }
}