@import "../helpers/variables.scss";

section#Servizi {
    //height: 100vh;
    padding-top: 100px;

    @media screen and (min-width: $tablet) {
        height: auto;
        margin-bottom: 100px;
    }

    @media screen and (min-width: $tablet) and (max-width: $desktop) {
        margin-bottom: 0;
    }

    .servizi-p {
        font-family: $font-lora;
        font-size: 26px;
        line-height: 34px;
        margin-top: 30px;

        @media screen and (min-width: $tablet) {
            font-family: $font-lora;
            font-size: 48px;
            line-height: 62px;
        }

        @media screen and (min-width: $tablet) and (max-width: $desktop) {
            font-size: 28px;
            line-height: 44px;
        }

        @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
            font-size: 32px;
            line-height: 44px;
        }

    }
}