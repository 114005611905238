//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 1024px,
  lg: 1200px,
  xl: 1559px,
  xxl: 1920px,
  container-xl:$container-xl + 48px,
  container-lg: $container-lg + 48px,
);


//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin media($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  
  // If the breakpoint doesn't exist in the map.
  } @else {

    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {

    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  
  // If one or both of the breakpoints don't exist.
  } @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {

      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {

      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

// Modal Mixin---------------------------------
@mixin modalFixed {
  position: fixed;
	left: 0;
  top: 0;
	width: 100%;
	background-color: rgba($blue, 0.8);
	height: 100vh;
	transition: 0.5s;
	opacity: 1;
  z-index: 200;
  overflow: auto;
  @include media (md) {
    padding: 150px 0 150px 0;
  }
}

@mixin containerModalText {
  max-width: 770px;
  margin: auto;
  padding: 140px 0 240px 0;
}

//  general rules
.modal_cancel {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 29px;
    height: 29px;
    cursor: pointer;
}

// dots and arrow  Slider-----------------------
@mixin dotsStyle {
  opacity: 1;
  cursor: pointer;
  background-color: $blue_3;
  margin: 0 5px;
}

@mixin arrowStyle {
  display: inline-block!important;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 24px;
  cursor: pointer;
  z-index: 10;
  transition: 0.5s;
  bottom: 91px;
  top: auto;
  
  @include media(sm) {
	width: 36px;
  }
}
//-------------------------------------
  
  
  
  

