footer {
    padding-top: 70px;
    padding-bottom: 100px;

    .footer {
        .footer-img-wrapper {
            max-width: 100vw;
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                max-width: 70px;

                @media screen and (min-width: $tablet) {
                    max-width: 250px;
                }
            }

            &::before {
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                background-color: #6DB9DE;
                transform: translateY(50px);
                z-index: -1;

                @media screen and (min-width: $tablet) {
                    transform: translateY(170px);

                }
            }

            @media screen and (min-width: $tablet) {}
        }

        .footer-address {
            text-align: center;
            font-size: 12px;
            line-height: 15px;
            color: #828282;
            font-family: $font-sans;

            @media screen and (min-width: $tablet) {
                font-size: 16px;
                line-height: 18px;
            }

            h3 {
                margin-bottom: 20px;
                margin-top: 25px;
                color: black;
                font-family: $font-lora;

                @media screen and (min-width: $tablet) {
                    font-size: 20px;

                }
            }

            li {
                margin-bottom: 2px;

                @media screen and (min-width: $tablet) {
                    margin-bottom: 4px;
                }

                &.privacy {
                    margin-top: 10px;

                    a {
                        position: relative;
                        display: inline-block;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 15px;
                            left: 0;
                            height: 1px;
                            width: 100%;
                            background-color: #828282;
                            opacity: 1;
                            transition: .3s;

                            @media screen and (min-width: $tablet) {
                                top: 19px;
                            }
                        }

                        &:hover {
                            &::after {
                                opacity: 0;
                                transition: .3s;
                            }
                        }
                    }

                    @media screen and (min-width: $tablet) {
                        margin-top: 20px;


                    }
                }
            }


        }
    }
}