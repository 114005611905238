@import '../helpers/variables.scss';

.container {
    max-width: 1660px;
    margin: 0 auto;
}


.header-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 46px;
    width: 46px;
    cursor: pointer;

    @media screen and (min-width: $desktop-lg) {
        height: 70px;
        width: 70px;
    }

    a:not(.popup-anchor) {
        font-size: 0;
    }

}

.blue-text {
    color: $blue;
    font-family: $font-lora;
    font-weight: 400;
}


.title {
    font-family: $font-sans;
    font-weight: 250;
    font-weight: lighter;
    letter-spacing: -2px;


    &.hero-title {

        font-size: 48px;
        text-align: center;
        line-height: 54px;
        width: auto;

        @media screen and (min-width: $tablet) {
            font-size: 128px;
            line-height: 120px;
            width: 960px;
            text-align: left;

        }

        @media screen and (min-width: $tablet) and (max-width: $desktop) {
            line-height: 60px;
            font-size: 64px;
            width: auto;
        }

        @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
            font-size: 80px;
            line-height: 90px;
        }

    }

    &.section-title {
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 3px;
        color: $black;
        position: relative;
        width: fit-content;


        &:after {
            content: '';
            position: absolute;
            height: 1px;
            background: $blue;
            width: calc(100% - 3px);
            top: 27px;
            left: 0;
        }
    }
}

#iubenda-cs-banner.iubenda-cs-default-floating:not(.iubenda-cs-top):not(.iubenda-cs-center) .iubenda-cs-opt-group button {
    font-family: $font-sans !important;
}

#iubenda-cs-banner p[class^=iub] {
    font-family: $font-sans !important;
    font-size: 14px !important;
}