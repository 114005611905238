@import "../helpers/variables.scss";

section#Contatti {
    position: relative;
    padding-top: 100px;

    &::before {
        content: '';
        background-image: linear-gradient(to bottom, transparent 0, transparent 250px, rgba(#6DB9DE, .2) 250px, #fff 60%);
        position: absolute;
        top: 0;
        left: -1200px;
        right: -1200px;
        bottom: 0;
        z-index: -1;
        max-width: 100vw;
        margin: 0 auto;
    }

    @media screen and (min-width: $tablet) {
        &::before {
            content: '';
            background-image: linear-gradient(to bottom, transparent 12%, rgba(#6DB9DE, .2) 12%, #fff 60%);
            position: absolute;
            top: 0;
            left: -1200px;
            right: -1200px;
            bottom: 0;
            z-index: -1;
            max-width: 100vw;
            margin: 0 auto;

        }
    }

    @media screen and (min-width: $desktop-lg) {
        &::before {
            content: '';
            background-image: linear-gradient(to bottom, transparent 15%, rgba(#6DB9DE, .2) 15%, #fff 60%);
            position: absolute;
            top: 0;
            left: -1200px;
            right: -1200px;
            bottom: 0;
            z-index: -1;
            max-width: 100vw;
            margin: 0 auto;
        }
    }

    .single-contatti-wrapper {
        margin-top: 60px;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $tablet) {
            flex-direction: row;
            padding: 0 40px;

        }

        @media screen and (min-width: $desktop-lg) {
            flex-direction: row;
            padding: 0 120px;

        }

        .contatti-img-wrapper {
            padding: 0 100px;
            max-width: 450px;
            margin: 0 auto;

            @media screen and (min-width: $tablet) {
                padding: 40px;
                width: 40%;
                flex-direction: row;
            }

            .contatti-img {
                width: 100%;
            }
        }

        .contatti-info-wrapper {
            max-width: 767px;
            margin: 0 auto;

            @media screen and (min-width: $tablet) {
                padding-top: 230px;
                width: 60%;
            }

            @media screen and (min-width: $tablet) and (max-width: $desktop) {
                padding-top: 130px;
            }

            .mobile-reverse {
                display: flex;
                flex-direction: column-reverse;

                .social-wrapper {
                    margin: 0 auto 20px;

                    @media screen and (min-width: $tablet) {
                        margin: 0;
                    }
                }

                @media screen and (min-width: $tablet) {
                    flex-direction: column;
                }
            }



            h2.nome {
                font-family: $font-lora;
                font-size: 30px;
                line-height: 38px;
                text-align: center;

                @media screen and (min-width: $tablet) {
                    font-size: 48px;
                    line-height: 62px;
                    margin-bottom: 10px;
                    text-align: left;
                }

                @media screen and (min-width: $tablet) and (max-width: $desktop) {
                    font-size: 36px;
                    line-height: 42px;
                }
            }

            h3.job {
                font-size: 14px;
                line-height: 16px;
                font-family: $font-sans;
                font-weight: 400;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 20px;

                @media screen and (min-width: $tablet) {
                    margin-bottom: 60px;
                    font-size: 16px;
                    line-height: 18px;
                    text-align: left;
                }

                @media screen and (min-width: $tablet) and (max-width: $desktop) {
                    margin-bottom: 30px;
                }
            }

            p.biography {

                font-family: $font-lora;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                padding: 0 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                /* number of lines to show */
                line-clamp: 5;
                -webkit-box-orient: vertical;
                transition: height .5s ease-in-out, max-height .5s ease-in-out;
                max-height: 90px;



                &.open-text {
                    text-overflow: none;
                    display: block;
                    -webkit-line-clamp: unset;
                    /* number of lines to show */
                    line-clamp: unset;
                    -webkit-box-orient: unset;
                    transition: height .5s ease-in-out, max-height .5s ease-in-out;
                    max-height: 700px;
                }

                @media screen and (min-width: $tablet) {
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 60px;
                    text-align: left;
                    padding: 0;
                    overflow: auto;
                    text-overflow: none;
                    display: block;
                    -webkit-line-clamp: unset;
                    /* number of lines to show */
                    line-clamp: unset;
                    -webkit-box-orient: unset;
                    height: auto !important;
                    max-height: unset !important;
                }

                @media screen and (min-width: $tablet) and (max-width: $desktop) {
                    font-size: 18px;
                    line-height: 24px;
                }

                @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
                    font-size: 20px;
                    line-height: 28px;
                }


            }

            .social-wrapper {
                display: flex;

                .contatti-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    height: 35px;
                    width: 35px;
                    cursor: pointer;

                    svg {
                        height: 15px;
                        width: 15px;
                    }

                    a {
                        font-size: 0;
                    }

                    @media screen and (min-width: $tablet) {
                        height: 70px;
                        width: 70px;

                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }

                    &.linkedin {
                        background-color: #007EBB;
                        margin-left: 15px;
                        border: 2px solid #007EBB;
                        transition: .3s;

                        &:hover {
                            background-color: white;
                            border: 2px solid #007EBB;
                            transition: .3s;

                            svg {
                                fill: #007EBB;
                                transition: .3s;
                            }
                        }
                    }

                    &.mail {
                        background-color: #6DB9DE;
                        border: 2px solid #6DB9DE;
                        transition: .3s;

                        svg {
                            @media screen and (min-width: $tablet) {
                                height: 29px !important;
                                width: 29px !important;

                            }
                        }

                        &:hover {
                            background-color: white;
                            border: 2px solid #6DB9DE;
                            transition: .3s;

                            svg {
                                fill: #6DB9DE;
                                transition: .3s;

                                @media screen and (min-width: $tablet) {
                                    height: 29px !important;
                                    width: 29px !important;

                                }
                            }
                        }
                    }
                }
            }

            .more-bio-btn {
                margin: 0 auto;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                color: #6DB9DE;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-family: $font-sans;

                .more-bio-icon {
                    height: 29px;
                    width: 29px;
                }

                svg {
                    fill: #6DB9DE;
                    margin-left: 10px;
                }

                @media screen and (min-width: $tablet) {
                    display: none;
                }
            }

        }

        &.img-right {

            @media screen and (min-width: $tablet) {
                margin-top: 250px;
                flex-direction: row-reverse;
            }
        }
    }
}