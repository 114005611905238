@import "../helpers/variables.scss";


.section-case-history {
    position: relative;
    //margin-top: -50px;
    padding: 100px 0 0;
    //height: 100vh;
    margin: 0 -10px;

    @media screen and (min-width: $tablet) {
        height: auto;
        margin-right: 0;
        padding: 0 10px;
        margin: 0 auto;

        &::before {
            content: '';
            background-image: linear-gradient(to bottom, transparent 15%, rgba(#6DB9DE, .2) 15%, #fff 60%);
            position: absolute;
            top: 0;
            left: -1200px;
            right: -1200px;
            bottom: 0;
        }
    }

    .title.section-title {
        margin-left: 20px;
        margin-bottom: 60px;

        @media screen and (min-width: $tablet) {
            margin-left: 0;
        }
    }

    .case-history-slider {
        margin-top: 30px;

        @media screen and (min-width: $tablet) {
            display: none;

        }

        .swiper-wrapper {
            .swiper-slide {
                padding: 10px;

                .slide-wrapper {
                    position: relative;
                    padding: 45px 0 120px;
                    border-radius: 50px;

                    img {
                        max-width: 100%;
                        width: 100%;
                    }

                    .know-more-btn {
                        margin: 0 auto;
                        width: auto;
                        text-transform: uppercase;
                        font-size: 16px;
                        letter-spacing: 2px;
                        font-weight: 700;
                        font-family: $font-sans;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        bottom: 20px;
                        left: 0;
                        right: 0;
                        color: $white;

                        .know-more-icon {
                            margin-left: 15px
                        }
                    }

                    &.slide-salmoiraghi {
                        background-color: #222222;
                    }

                    &.slide-vigilantes {
                        background-color: #0C3853;
                    }

                    &.slide-unibirra {
                        background-color: #005F37;
                    }

                    &.slide-askoll {
                        background-color: #d9d9d9;
                    }


                }
            }
        }
    }

    .slide-case-popup {

        overflow-y: auto;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        font-family: $font-sans;
        line-height: 18px;
        opacity: 0;
        transition: opacity .3s ease, visibility .3s, z-index 0s .4s;
        visibility: hidden;

        &.case-visible {

            opacity: 1 !important;
            transition: opacity .3s ease, visibility .3s;
            z-index: 999;
            visibility: visible !important;
        }

        .close-case-btn {
            position: absolute;
            right: 0;
            background: white;
            border-radius: 0 0 0 30px;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            padding-top: 100px;
            width: 100%;
        }

        &.case-salmoiraghi {
            background-color: $salmoiraghi
        }

        &.case-vigilantes {
            background-color: $vigilantes
        }

        &.case-unibirra {
            background-color: $unibirra
        }

        &.case-askoll {
            background-color: $askoll
        }

        .case-content {
            padding: 0 20px;
            color: white;

            .case-title {
                display: inline-block;
                font-size: 30px;
                line-height: 38px;
                margin-bottom: 20px;
                width: 100%;

                font-family: $font-lora;

                span {
                    font-weight: 400;
                }
            }

            .case-infos {
                li {
                    margin-bottom: 20px;
                }
            }
        }

        .go-to-btn {
            margin: 60px auto;
            width: 310px;
            border: 2px solid white;
            border-radius: 50px;
            line-height: 70px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-family: $font-sans;

            svg {
                margin-left: 10px;
                fill: white;
                display: block;
                visibility: visible;
            }


        }

    }

    .desktop-case-history-wrapper {
        display: none;
        margin-top: 90px;


        @media screen and (min-width: $tablet) {
            display: block;
        }

        .single-case-wrapper {
            position: relative;
            display: flex;
            margin-top: 30px;
            background: #fff;
            border-radius: 50px;
            border: 1px solid rgba(#636466, .3);
            min-height: 485px;

            .case-image {
                max-width: 30%;
                display: flex;
                align-items: center;
                border-radius: 50px 0 0 50px;

                img {
                    width: 100%;
                }
            }

            .case-content {

                width: 70%;
                padding: 235px 70px 235px 40px;
                border-radius: 0 50px 50px 0;
                font-family: $font-sans;

                @media screen and (min-width: $tablet) and (max-width: $desktop) {
                    padding: 40px 70px 135px 40px;

                }

                @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
                    padding: 40px 70px 135px 40px;
                }


                .case-title {
                    display: inline-block;
                    font-size: 40px;
                    line-height: 52px;
                    margin-bottom: 20px;
                    width: 100%;
                    max-width: 1660px;


                    font-family: $font-lora;

                    @media screen and (min-width: $tablet) and (max-width: $desktop) {
                        font-size: 20px;



                    }

                    span {
                        font-weight: 400;
                    }
                }

                .descr-content {
                    display: flex;

                    @media screen and (min-width: $tablet) and (max-width: $desktop) {

                        font-size: 12px;

                    }

                    .left-content {
                        width: 50%;

                        .case-infos {

                            li {
                                margin-bottom: 20px;
                            }
                        }
                    }

                    .right-content {
                        width: 50%;
                        margin-left: 30px;
                    }
                }

                .go-to-btn {
                    position: absolute;
                    bottom: 40px;
                    right: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px;
                    padding: 10px 20px;
                    width: 310px;
                    color: white;
                    transition: .3s;
                    cursor: pointer;
                    font-weight: 700;
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    font-size: 16px;


                    svg {
                        fill: white;
                        margin-left: 10px;
                        transition: .3s;
                    }

                    @media screen and (min-width: $tablet) and (max-width: $desktop) {
                        padding: 5px 10px;
                        font-size: 12px;

                    }

                    &.case-salmoiraghi {
                        background-color: $salmoiraghi;
                        border: 2px solid $salmoiraghi;


                        &:hover {
                            background: white;
                            transition: .3s;
                            color: $salmoiraghi;

                            svg {
                                fill: $salmoiraghi;
                                transition: .3s;
                            }
                        }
                    }

                    &.case-vigilantes {
                        background-color: $vigilantes;
                        border: 2px solid $vigilantes;


                        &:hover {
                            background: white;
                            transition: .3s;
                            color: $vigilantes;

                            svg {
                                fill: $vigilantes;
                                transition: .3s;
                            }
                        }
                    }

                    &.case-unibirra {
                        background-color: $unibirra;
                        border: 2px solid $unibirra;



                        &:hover {
                            background: white;
                            transition: .3s;
                            color: $unibirra;

                            svg {
                                fill: $unibirra;
                                transition: .3s;
                            }
                        }
                    }

                    &.case-askoll {
                        color: black;
                        background-color: $askoll;
                        border: 2px solid $askoll;


                        svg {
                            fill: black;
                        }

                        &:hover {
                            background: white;
                            transition: .3s;
                            //color: $askoll;

                            svg {
                                //fill: $askoll;
                                transition: .3s;
                            }
                        }
                    }
                }
            }

            &.img-right {
                flex-direction: row-reverse;

                .go-to-btn {
                    left: 40px;
                    right: auto;
                }

                .case-image {
                    border-radius: 0 50px 50px 0;
                }

                .case-content {
                    border-radius: 50px 0 0 50px;
                }
            }
        }
    }
}

.single-case-wrapper {

    &.case-salmoiraghi {
        .case-image {
            background-color: $salmoiraghi;
        }
    }

    &.case-vigilantes {
        .case-image {
            background-color: $vigilantes;
        }
    }

    &.case-unibirra {
        .case-image {
            background-color: $unibirra;
        }
    }

    &.case-askoll {
        .case-image {
            background-color: $askoll;
        }
    }
}