.progress-bar {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 450px;
    width: 4px;
    border-radius: 3px;
    background-color: $gray-2;
    display: none;

    @media screen and (min-width: $desktop) {
        display: block;
    }

    .inner-progress-bar {
        width: 4px;
        height: 0;
        background-color: $popup-blue;
        border-radius: 3px;
        max-height: 100% !important;

    }

    .progress-title {
        writing-mode: vertical-lr;
        position: absolute;
        right: 9px;
        transform: rotate(180deg);
        font-family: $font-sans;
        letter-spacing: 5px;
        text-transform: uppercase;
        font-weight: 400;
        top: 20px;
        color: $popup-blue;
    }
}

.scrollTopBtn {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: end;
    max-width: 1660px;
    margin: 0 auto;
    z-index: 99;
    transition: opacity .3s 0.1s;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;

    &.visible {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        transition: opacity .3s 0.1s;
    }

    svg {
        transition: .1s;

        circle {
            transition: .1s;
        }
    }

    &:hover {
        svg {
            fill: #6DB9DE;
            transition: .1s;

            circle {
                fill: white;
                transition: .1s;
            }
        }
    }
}