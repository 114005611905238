@import "../helpers/variables.scss";

.section-about {
    position: relative;
    margin-top: -50px;
    padding-top: 0; //height: 100vh;
    margin-bottom: 20px;

    @media screen and (min-width: $tablet) {
        margin-top: -80px;
        padding-top: 200px;
        height: auto;
        margin-bottom: 0;

    }

    .about-slider {
        z-index: 0;
        margin-top: 60px;

        @media screen and (min-width: $tablet) {
            margin-top: 160px;
        }

        .swiper-slide {
            opacity: 0 !important;
            //height: 80vh;
            padding-bottom: 60px;

            @media screen and (min-width: $tablet) {
                height: auto;

            }

            &.swiper-slide-active {
                opacity: 1 !important;
            }
        }

        .slide-content {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $tablet) {
                flex-direction: row;
            }

            .about-slide-title {
                letter-spacing: -2px;
                font-weight: 250;
                font-family: $font-sans;
                font-size: 36px;
                line-height: 46px;
                margin-bottom: 20px;

                width: 100%;

                @media screen and (min-width: $tablet) {
                    width: 50%;
                    font-size: 84px;
                    line-height: 98px;
                    margin-bottom: 0px;
                }

                @media screen and (min-width: $tablet) and (max-width: $desktop) {
                    font-size: 44px;
                    line-height: 60px;
                }

                @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
                    font-size: 60px;
                    line-height: 72px;
                }
            }

            .slide-text {
                width: 100%;
                font-size: 18px;
                line-height: 23px;

                @media screen and (min-width: $tablet) {
                    font-size: 24px;
                    line-height: 30px;
                    width: 50%;
                    padding-left: 10px;
                }

                @media screen and (min-width: $tablet) and (max-width: $desktop) {
                    font-size: 18px;
                    line-height: 24px;
                }

                @media screen and (min-width: $desktop) and (max-width: $desktop-lg) {
                    font-size: 20px;
                    line-height: 26px;
                }

                .blue-text {
                    font-weight: 600;
                }
            }
        }
    }

    .slider-navigation {
        display: none;

        @media screen and (min-width: calc($tablet + 1px)) {
            position: absolute;
            top: 340px;
            left: 100px;
            width: 160px;
            z-index: 0;

            display: block;

        }

        @media screen and (min-width: $desktop) {
            display: block !important;


        }
    }

    .swiper-pagination {
        top: 205px;
        left: 10px !important;
        bottom: 0px !important;
        font-family: $font-sans;
        font-size: 24px;
        z-index: 0;
        //display: none;
        width: auto;

        @media screen and (min-width: $tablet) {
            width: 100px;
            display: block;
            top: 305px;
            bottom: auto;
            left: 0 !important;
        }

        .swiper-pagination-current {
            font-size: 48px;
            color: $blue;
            font-weight: 400;
        }

        .swiper-pagination-total {
            font-family: $font-lora;
        }

        .swiper-pagination-bullet-active {
            background: $blue;
            scale: 1.3;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 60px !important;

        svg {
            transition: .3s;
        }

        &:hover {
            svg {
                transition: .3s;
                fill: #2E6C85 !important;
            }
        }
    }

    .swiper-button-next {
        transform: rotate(180deg);
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        display: none;
    }
}